@use "../config/" as *;
.waf-update-profile {
    @extend %p-0;
    --_setting-height: 5rem;
    .waf-head {
        height: var(--_setting-height);
    }
    .setting {
        &-list {
            width: 17rem;
            z-index: var(--z-user-profile-setting);
            @include position(var(--space-10), var(--space-2), null, null);
            @extend %gap-2;
            @extend %d-none;
            @extend %py-3;
            @extend %pure-white-900-bg;
            @extend %half-radius;
        }
        &-item {
            height: 2.6rem;
            &.active {
                @extend %tertiary-100-bg;
            }
            .edit-profile {
                &:before {
                    @include icon(edit);
                }
            }
            .delete {
                &:before {
                    @include icon(delete);
                }
            }
            .logout {
                &:before {
                    @include icon(logout);
                }
            }
            .recover {
                &:before {
                    @include icon(restore);
                }
            }
        }
        &-text {
            @extend %neutral-800;
            @extend %capitalize;
            @extend %font-12-pm;
            &:before {
                @extend %pr-2;
                @extend %neutral-800;
            }
        }
        &-link {
            @extend %flex-n-c;
            @extend %px-3;
            @extend %w-100;
            @extend %h-100;
        }
        &-profile {
            @extend %px-2;
            @extend %h-100;
            @extend %flex;
            .btn-setting {
                height: 3rem;
                @extend %mt-auto;
                @extend %ml-auto;
                @extend %gap-1;
                @extend %flex-n-c;
                &::before {
                    @extend %pure-white-900;
                    @include icon(setting, 14);
                }
                &[aria-expanded="true"] {
                    & ~ .setting-list {
                        @extend %flex-column;
                    }
                }
            }
            .btn-text {
                @extend %uppercase;
                @extend %pure-white-900;
                @extend %font-14-pb;
            }
        }
    }
    .jersey {
        &-section {
            @extend %mt-4;
        }
        &-image {
            width: 26rem;
            height: 36rem;
            @extend %relative;
            @extend %text-center;
            @extend %mx-auto;
        }
        &-info {
            @extend %flex-column;
            @extend %pos-center;
            top: 55%;
        }
        &-name {
            @extend %font-22-pb;
            line-height: 2.2rem;
        }
        &-number {
            @extend %pt-2;
            @extend %font-40-pb;
        }
        &-name,
        &-number {
            @extend %capitalize;
            @extend %pure-white-900;
        }
    }
    .user-masthead {
        @extend %px-2;
        @extend %py-6;
        @extend %mx-2;
        @extend %relative;
        &::before {
            content: '';
            z-index: var(--z-user-profile-bg);
            width: calc(100% + var(--space-4));
            height: calc(100% + var(--_setting-height));
            @include background(null, "pattern/mobile/user-profile-bg.png", top / cover no-repeat);
            @include position(calc(-1*var(--_setting-height)), null, null, var(--space-2-neg));
        }
        &::after {
            content: '';
            background: linear-gradient(180deg, clr(secondary-900, 6) 68.48%, clr(secondary-900, 0) 100%);
            border-radius: var(--full-radius) var(--full-radius) 0 0;
            z-index: var(--z-user-profile-bg);
            backdrop-filter: blur(3rem);
            @extend %w-100;
            @extend %h-100;
            @extend %pos-tl;
        }
    }
    .user-info {
        @extend %mb-8;
        @extend %gap-2;
        @extend %relative;
        @extend %flex-n-c;
        &::after {
            content: '';
            height: .1rem;
            @extend %w-100;
            @extend %primary-500-bg;
            @include position(null, null, var(--space-4-neg), 0);
        }
        .user-img {
            flex-shrink: 0;
            @extend %relative;
            @include square(10rem);
            @extend %neutral-300-bg;
            @extend %circle-radius;
            img {
                @extend %circle-radius;
                @extend %p-2;
            }
            &-text {
                @extend %secondary-900;
                @extend %font-40-pb;
                @extend %w-100;
                @extend %h-100;
                @extend %uppercase;
                @extend %flex-c-c;
            }
        }
        .user-name {
            .text {
                @extend %pure-white-900;
                @extend %capitalize;
                @extend %font-24-pb;
            }
        }
        .show-avatar {
            cursor: pointer;
            @extend %d-none;
            @include position(7rem, null, null, 8rem);
            .image-upload {
                @extend %d-none;
            }
            &::after {
                @include square(2rem);
                @include icon(edit, 12);
                @extend %circle-radius;
                @extend %pure-white-900-bg;
                @extend %neutral-800;
                @extend %flex-c-c;
            }
        }
    }
    .profile-form {
        &-list {
            @extend %flex-column;
            @extend %gap-4;
        }
        &-value {
            @extend %font-14-pm;
            @extend %neutral-500;
        }
    }
    .form-element {
        height: 5rem;
        &-text {
            @extend %neutral-5;
            @extend %pl-4;
            @extend %pt-1;
            @extend %d-block;
            @extend %font-14-pm;
        }
    }
    .form-label {
        position: unset;
        font-size: 1.2rem;
        @extend %capitalize;
        @extend %secondary-800;
    }
    .form-dropdown,
    .btn-drop,
    .form-input,
    .form-select-box {
        @extend %d-none;
    }
    .btn-text {
        @extend %font-14-pm;
        @extend %neutral-500;
    }
    .btn-save {
        .btn {
            height: 4.4rem;
            border-radius: var(--half-radius);
            @extend %w-100;
            @extend %flex-c-c;
            @extend %tertiary-600-bg;
        }
        .btn-text {
            @extend %font-14-pb;
            @extend %uppercase;
            @extend %neutral-800;
        }
    }
    .form-mobile {
        .error-msg {
            padding-inline: var(--space-4);
            @extend %text-left;
        }
    }
    &.edit-profile {
        .profile-form-value {
            @extend %d-none;
        }
        .form-label {
            @extend %absolute;
        }
        .form-input {
            @extend %neutral-5-bg;
        }
        .form-dropdown,
        .form-input,
        .btn-drop,
        .form-select-box {
            @extend %d-block;
        }
        .btn-drop {
            @extend %flex-sb-n;
        }
        .form-element {
            &.dropdown {
                @include flex-config(flex, column-reverse, center, null);
                .form-label {
                    position: unset;
                    translate: unset;
                }
            }
        }
        .form-mobile {
            .form-select-box {
                height: 5rem;
                .selected-title {
                    height: 5rem;
                }
            }
        }
        .favourite-player-info {
            .btn-fav-player {
                &[aria-expanded="true"] {
                    & ~ .form-dropdown {
                        @include dropdown(open);
                    }
                }
                .btn-text {
                    @extend %gap-3;
                    @include flex-config(flex, row-reverse, center, center);
                    &::before {
                        @extend %pure-white-900;
                        @include icon(chevron-down);
                    }
                }
            }
            .player-thumbnail {
                &::before {
                    bottom: 0;
                }
            }
        }
        .show-avatar {
            @extend %flex;
        }
    }
    .favourite-player-info {
        z-index: 1;
        @extend %mt-4;
        @extend %pt-4;
        @extend %relative;
        @include background(clr(primary-900), "pattern/mobile/squad-bg.png", top/cover);
        .title {
            @extend %px-2;
            @extend %pb-2;
            @extend %capitalize;
            @extend %font-18-pb;
            @extend %pure-white-900;
        }
        .player {
            &-item {
                @extend %relative;
            }
            &-thumbnail {
                @extend %relative;
                img {
                    width: 23.5rem;
                    margin-right: var(--space-8);
                    @extend %ml-auto;
                }
                &:before {
                    content: '';
                    height: 30rem;
                    z-index: var(--z-homesquad-pattern);
                    @extend %w-100;
                    @include background(null, "pattern/mobile/squad-roar-bg.png", bottom/cover no-repeat);
                    @include position(null, null, var(--space-4), 0);
                }
            }
            &-name {
                border-radius: var(--half-radius) var(--half-radius) 0 0;
                @include separator(90%);
                @extend %w-100;
                @extend %neutral-900-bg-2;
                @extend %p-3;
                @extend %text-center;
                .btn-text {
                    @extend %tertiary-600;
                    @extend %font-22-pb;
                }
            }
            &-meta {
                border-radius: 0 0 var(--half-radius) var(--half-radius);
                @extend %neutral-900-bg-2;
                @extend %p-3;
                @extend %flex-c-c;
                @extend %gap-1;
            }
            &-role {
                @extend %flex-c-c;
                @extend %gap-3;
                .role {
                    @extend %font-14-pm;
                    @extend %primary-50;
                    &:not(:last-child)::after {
                        opacity: 0.5;
                        content: "|";
                        @extend %primary-50;
                        @extend %ml-2;
                    }
                }
            }
            &-stats {
                &-list {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    @extend %text-center;
                    @extend %gap-1;
                    @extend %my-2;
                }
                &-item {
                    flex-direction: column-reverse;
                    backdrop-filter: blur(4rem);
                    @extend %flex;
                    @extend %half-radius;
                    @extend %neutral-900-bg-2;
                    @extend %p-3;
                    @extend %mb-2;
                }
                &-count {
                    border-bottom: .1rem solid clr(primary-600, 6);
                    @extend %tertiary-600;
                    @extend %font-18-pb;
                    @extend %mb-2;
                    @extend %pb-2;
                }
                &-title {
                    @extend %primary-50;
                    @extend %font-14-pm;
                }
            }
            &-content {
                background: linear-gradient(180deg, clr(primary-900, 5) 39.33%, transparent 100%);
                backdrop-filter: blur(4rem);
                @extend %relative;
                @extend %mt-4-neg;
                @extend %p-2;
                @extend %half-radius;
            }
        }
        .card-action {
            grid-column: span 3
        }
        .btn-site {
            border: .1rem solid clr(tertiary-600);
            max-width: 20rem;
            cursor: pointer;
            transition: background-color .3s linear;
            @extend %mx-auto;
            @extend %half-radius;
            @extend %d-block;
            @extend %font-14-pb;
            @extend %uppercase;
            @extend %p-2;
            @extend %tertiary-600;
            &:hover {
                @extend %neutral-800;
                @extend %tertiary-600-bg;
            }
        }
        .overseas {
            &::after {
                position: absolute;
                top: 30%;
                left: var(--space-2);
                width: 2.8rem;
                height: 2.8rem;
                @include icon(overseas);
                @extend %neutral-900;
                @extend %flex-c-c;
                @extend %neutral-50-bg;
                @extend %circle-radius;
            }
        }
        .captain {
            &::before {
                position: absolute;
                top: calc(30% - 4rem);
                left: var(--space-2);
                width: 2.8rem;
                height: 2.8rem;
                content: "C";
                @extend %pure-white-900;
                @extend %font-16-pb;
                @extend %flex-c-c;
                @extend %accent-900-bg;
                @extend %circle-radius;
            }
        }
        .form-dropdown {
            height: 15.5rem;
            width: calc(100% - var(--space-4));
            overflow-y: auto;
            z-index: var(--z-form-dropdown);
            border-radius: 0 0 var(--half-radius) var(--half-radius);
            @extend %px-8;
            @extend %primary-900-bg;
            @include position(var(--space-13), null, null, var(--space-2));
            @include dropdown(close);
            .text {
                border-bottom: .1rem solid var(--neutral-400);
                @extend %pb-2;
                @extend %flex;
                @extend %w-100;
                @extend %uppercase;
                @extend %pure-white-900;
                @extend %font-10-pm;
            }
            .list-item {
                @extend %relative;
                &:not(:last-child):after {
                    content: "";
                    height: .1rem;
                    width: 100%;
                    @extend %pos-x-center;
                    @extend %d-block;
                    @extend %neutral-400-bg;
                }
            }
            .player {
                &-image {
                    width: 4.4rem;
                    height: 4.4rem;
                    flex-shrink: 0;
                    border: .1rem solid clr(tertiary-800);
                    @extend %circle-radius;
                    img {
                        object-fit: contain;
                        @extend %w-100;
                        @extend %h-100;
                        @extend %circle-radius;
                    }
                }
                &-name {
                    border-radius: 0;
                    @extend %flex-column;
                    @extend %text-left;
                    @extend %p-0;
                    @extend %transparent-bg;
                    &:after {
                        @extend %d-none;
                    }
                    .name {
                        @extend %capitalize;
                        @extend %pure-white-900;
                        @extend %font-14-pm;
                    }
                }
            }
            .btn-list {
                @extend %py-3;
                @extend %gap-2;
                @extend %flex-n-c;
                @extend %w-100;
            }
        }
    }
}
@include mq(col-md) {
    .waf-update-profile {
        .layout-wrapper {
            max-width: 100%;
        }
        .user-masthead {
            padding-inline: var(--space-6);
            margin-inline: var(--space-6);
            &::before {
                width: calc(100% + var(--space-12));
                left: var(--space-6-neg);
            }
        }
        .favourite-player-info {
            .title {
                padding-inline: var(--space-6);
            }
            .player {
                &-content {
                    padding: var(--space-6);
                }
                &-thumbnail {
                    img {
                        margin-inline: auto;
                    }
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-update-profile {
        background: linear-gradient(180deg, var(--primary-900) 0%, var(--primary-700) 100%);
        --_setting-height: 8rem;
        .layout-wrapper {
            max-width: var(--container-max-width);
        }
        .user-info {
            grid-row: 1/2;
            grid-column: 1/2;
            .user-img {
                width: 12rem;
                height: 12rem;
            }
            .user-name {
                .text {
                    font-size: 3.2rem;
                }
            }
            .show-avatar {
                top: 8rem;
                left: 10rem;
            }
        }
        .setting {
            &-profile {
                .btn-setting {
                    height: 4rem;
                }
            }
            &-list {
                border-radius: var(--full-radius);
                padding-block: var(--space-4);
                top: var(--_setting-height);
                z-index: var(--z-user-profile-setting-web);
                right: var(--container-white-space);
            }
        }
        .user-masthead {
            margin-inline: 0;
            padding-inline: 9rem;
            display: grid;
            grid-template-columns: 65% 35%;
            z-index: var(--z-user-profile-setting);
            &::before {
                content: unset;
            }
            &::after {
                border-radius: var(--full-radius);
                background: linear-gradient(180deg, clr(secondary-900, 6) 71.32%, clr(secondary-900, 0) 104.15%);
            }
        }
        .profile-form-list {
            grid-row: 2/3;
            grid-column: 1/2;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            row-gap: var(--space-4);
            column-gap: var(--space-3);
        }
        .jersey {
            &-section {
                grid-row: 1/3;
                grid-column: 2/3;
                align-content: center;
                margin-top: 0;
            }
            &-image {
                margin-right: 0;
                width: auto;
                height: 40rem;
                aspect-ratio: 3 / 4;
            }
        }
        .profile-form-item {
            width: calc(50% - .75rem);
            &.btn-save {
                margin-inline: auto;
                width: 50%;
            }
            &.varified {
                .form-element {
                    padding-right: var(--space-9);
                }
                .profile-form-value {
                    overflow-x: auto;
                    @include custom-scroll(0,0);
                }
            }
        }
        .favourite-player-info {
            margin-top: 0;
            padding-top: var(--space-10);
            background: transparent;
            &::before {
                content: '';
                width: var(--window-inner-width);
                height: 100%;
                @include position(null, null, 0, calc(-1*var(--container-white-space)));
                @include background(null, "pattern/user-profile-player-bg.png", bottom / contain no-repeat);
            }
            .title {
                padding-left: 0;
                font-size: 3.2rem;
            }
            .player {
                &-role {
                    .role {
                        font-size: 1.8rem;
                    }
                }
                &-stats-title {
                    font-size: 1.6rem;
                }
                &-stats-count {
                    font-size: 2.4rem;
                }
                &-name {
                    .btn-text {
                        font-size: 3.2rem;
                    }
                }
                &-item {
                    display: flex;
                    justify-content: flex-end;
                }
                &-thumbnail {
                    width: 45%;
                    margin-right: 5%;
                    &::before {
                        content: unset;
                    }
                    img {
                        width: 100%;
                    }
                }
                &-wrap {
                    display: flex;
                    justify-content: space-between;
                    width: 70%;
                }
                &-content {
                    padding: var(--space-4);
                    margin-top: var(--space-10);
                    height: max-content;
                    width: calc(100% - 50%);
                }
            }
            .captain {
                &::before {
                    left: auto;
                    right: 40%;
                    top: calc(20% - 4rem);
                }
            }
            .overseas {
                &::after {
                    left: auto;
                    right: 40%;
                    top: 20%;
                }
            }
            .form-dropdown {
                height: 17rem;
                width: calc(100% - var(--space-4));
                top: 8.5rem;
                left: var(--space-4);
            }
        }
    }
}